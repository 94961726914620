import { React, useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import Cookies from 'js-cookie';
import { AES, enc } from 'crypto-js';

export default function Dashboard() {
  // Get token
  const token = Cookies.get('__stkn') ? AES.decrypt(Cookies.get('__stkn'), 'Tugubro@1976').toString(enc.Utf8) : null;
  // get data
  const [dataPolis, setDataPolis] = useState([]);
  const [dataMember, setDataMember] = useState([]);

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .get("https://health-service.tib.co.id/api/dashboard")
      .then(({ data }) => {
        // console.log(data.dataList);
        setDataPolis(data.dataClient[0].polis);
        setDataMember(data.dataClient[0].member);
      })
      .catch((error) => console.log(error));
  };

  const columns = [
    {
      name: "MEMBER NAME",
      selector: (row) => row.fname,
      sortable: true,
      width: "300px",
    },
    {
      name: "DOB",
      selector: (row) => row.dob,
      sortable: true,
    },
    {
      name: "GENDER",
      selector: (row) => row.sex,
      sortable: true,
    },
    {
      name: "STATUS",
      selector: (row) => row.stat_emp,
      sortable: true,
    },
    {
      name: "PLAN",
      selector: (row) => row.plan,
      sortable: true,
    },
    {
      name: "OP",
      selector: (row) => row.plan_op,
      sortable: true,
    },
    {
      name: "MA",
      selector: (row) => row.plan_ma,
      sortable: true,
    },
    {
      name: "DT",
      selector: (row) => row.plan_dt,
      sortable: true,
    },
    {
      name: "GL",
      selector: (row) => row.plan_gl,
      sortable: true,
    },
  ];

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-full/12 mb-12 xl:mb-0 px-4">
          <div className="relative flex flex-col min-w-0 break-words bg-red-600 w-full mb-6 shadow-lg rounded">
            <div className="rounded-t mb-0 px-4 py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-1 max-w-full flex-grow flex-1">
                  <h3 className="font-semibold text-base text-white">
                    {dataPolis.name}
                  </h3>
                  <p className="text-sm text-white flex-wrap">
                    {dataPolis.crt_name}
                    <span>
                      ( {dataPolis.period_start} - {dataPolis.period_end} )
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <DataTable
              columns={columns}
              data={dataMember}
              direction="auto"
              fixedHeader
              fixedHeaderScrollHeight="500px"
              highlightOnHover
              noContextMenu
              persistTableHead
              responsive
              subHeaderAlign="right"
              subHeaderWrap
            />
          </div>
        </div>
      </div>
    </>
  );
}
