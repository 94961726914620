import {React, useEffect, useState, useRef} from "react";
import { useHistory } from 'react-router';
import { Switch, Route, Redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios';
import LoadingBar from "react-top-loading-bar";
import { AES, enc } from 'crypto-js';

// components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views
import Dashboard from "views/admin/Dashboard.js";

export default function Admin() {

  const [user, setUser] = useState({});
  const history = useHistory();
  
  // Loading bar
  const progress = useRef(null);

  useEffect(() => {
      let isMounted = true;  
      
      progress.current.staticStart();

      // get token;
      const token = Cookies.get('__stkn') ? AES.decrypt(Cookies.get('__stkn'), 'Tugubro@1976').toString(enc.Utf8) : null;
      
      const fetchData = async () => { 
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          await axios.get('https://health-service.tib.co.id/api/user')
          .then((response) => {
            // console.log(response);
            if (isMounted) {
              setUser(response.data);
              setTimeout(() => {
                progress.current.complete();
              }, 1000);
            }
          })
          .catch((error) => {
            Cookies.remove('__stkn');
            history.push('/');
          });
      }
        //check token
        if(!token) {
            history.push('/');
        }
        fetchData();
        
        return () => {
          isMounted = false;
        };
    }, [history]);

  return (
    <>
    <LoadingBar color="#25CCF7" ref={progress} shadow={true} height={5} />
      <Sidebar dataUser={user}/>
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar textNavbar={"Dashboard"}/>
        {/* Header */}
        <HeaderStats dataUser={user}/>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            <Route path="/dashboard" component={Dashboard} />
            <Redirect from="/" to="/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
