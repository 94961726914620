import { React, useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import { useDropzone } from "react-dropzone";
import Swal from 'sweetalert2';
import Cookies from "js-cookie";
import { AES, enc } from "crypto-js";

export default function ClaimInput() {
  // get data
  const [members, setMember] = useState([]);
  const [currencies, setCurr] = useState([]);
  const [benefits, setBenefit] = useState([]);
  const [arBenefit, setArBenefit] = useState([]);
  const [arUploadFile, setArUploadFile] = useState([]);

  // insert data
  const [insId, setInsId] = useState("");
  const [empId, setEmpId] = useState("");
  const [claimDate, setClaimDate] = useState("");
  const [claimCurr, setClaimCurr] = useState("1");
  const [claimAmt, setClaimAmt] = useState("");
  const [diagDesc, setDiagDesc] = useState("");
  const [claimBenefit, setClaimBenefit] = useState("");
  // Dropzone
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  // Get token
  const token = Cookies.get("__stkn")
    ? AES.decrypt(Cookies.get("__stkn"), "Tugubro@1976").toString(enc.Utf8)
    : null;

  const history = useHistory();

  // loading
  const [loading, setLoading] = useState(false);

  let benefitOption = null;
  let uploadFile = null;
  let tmpInsId = null;
  let tmpEmpId = null;
  let tmpBenefitId = null;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .get("https://health-service.tib.co.id/api/claim/data")
      .then(({ data }) => {
        // set data from api
        setMember(data.member);
        setCurr(data.curr);
        setBenefit(data.benefit);
      })
      .catch((error) => console.log(error));
  };

  const handleChangeBenefitOption = (e) => {
    // set array benefit empty
    setArBenefit([]);

    // for benefit select option
    benefits.forEach((value) => {
      if (e.target.value === value.empId.toString()) {
        benefitOption = value.benefitData.map((d) => ({
          key: d.benefit_name,
          value: d.id,
          uploadFile: d.upload_file,
        }));
      }
    });
    // set
    setArBenefit(benefitOption);

    // for input
    // eslint-disable-next-line array-callback-return
    members.map((member) => {
      if (e.target.value === member.id.toString()) {
        tmpInsId = member.ins_id;
        tmpEmpId = member.id;
      }
    });

    //set
    setEmpId(tmpEmpId);
    setInsId(tmpInsId);
  };

  const handleChangeUploadFile = (e) => {
    // set array benefit empty
    setArUploadFile([]);

    // for benefit select option
    arBenefit.forEach((valueBenefit) => {
      if (e.target.value === valueBenefit.value.toString()) {
        uploadFile = valueBenefit.uploadFile;
        tmpBenefitId = valueBenefit.value;
      }
    });
    // set
    setArUploadFile(uploadFile);
    setClaimBenefit(tmpBenefitId);
  };

  // if array benefit is not null
  if (arBenefit) {
    benefitOption = arBenefit.map((benefit, index) => (
      <option key={index + 1} value={benefit.value}>
        {benefit.key}
      </option>
    ));
  }

  // list file
  const files = acceptedFiles.map((file) => (
    <li
      className="py-2 px-4 w-full border-b border-gray-200 dark:border-gray-600"
      key={file.path}
    >
      {file.path} - {file.size} bytes
    </li>
  ));

  const submitHandler = async (e) => {
    // stop browser's default behavior of reloading on form submit
    e.preventDefault();

    const formData = new FormData();

    formData.append("insId", insId);
    formData.append("empId", empId);
    formData.append("claimDate", claimDate);
    formData.append("claimCurr", claimCurr);
    formData.append("claimAmt", claimAmt);
    formData.append("diagDesc", diagDesc);
    formData.append("benefit", claimBenefit);
    // upload multiple file
    acceptedFiles.map((file) => formData.append("fileUpd[]", file, file.path));
    // loading before call API
    setLoading(true);

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.post["Accept"] = "application/json";
    await axios
      .post("https://health-service.tib.co.id/api/claim/insert", formData)
      .then((response) => {
        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: response.data.message,
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
          allowOutsideClick: false
        }).then(history.push(`/claim/${response.data.claimId}`));
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Failed!",
          text: "Periksa kembali form input",
          icon: "warning",
          timer: 1500,
          showConfirmButton: false,
          allowOutsideClick: false
        });
      });
  };

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:w-full/12 mb-12 xl:mb-0 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-red-600 border-0">
            <div className="rounded-t mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-white text-xl font-bold">
                  Claim Input
                </h6>
              </div>
            </div>
            <div className="flex-auto bg-white px-4 lg:px-10 py-10 pt-0">
              <form onSubmit={submitHandler}>
                <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Claim Input
                </h6>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Member Name
                      </label>
                      <select
                        name="memberName"
                        id="memberName"
                        onChange={handleChangeBenefitOption}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      >
                        <option value="0">--- Select Member ---</option>
                        {members.map((member, index) => {
                          return (
                            <option key={index + 1} value={member.id}>
                              {member.fname}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Claim Date
                      </label>
                      <input
                        type="date"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={(e) => setClaimDate(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Claim Amount
                        </label>
                      <div className="flex">
                        <div className="flex-none">
                        <select
                          name="currency"
                          id="currency"
                          className="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                          onChange={(e) => setClaimCurr(e.target.value)}
                        >
                          {currencies.map((currency, index) => {
                            return (
                              <option key={index + 1} value={currency.id}>
                                {currency.curr_code}
                              </option>
                            );
                          })}
                        </select>
                        </div>
                        <div className="flex-auto">
                        <NumericFormat
                          thousandSeparator={true}
                          onChange={(e) => setClaimAmt(e.target.value)}
                          placeholder="Enter claim amount"
                          className="border-0 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm text-right shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mt-6 mb-3 border-b-1 border-blueGray-300" />
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Benefit
                      </label>
                      <select
                        name="benefit"
                        id="benefit"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChangeUploadFile}
                      >
                        <option value="0">--- Select Benefit ---</option>
                        {benefitOption}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-12/12 px-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Diagnose
                      </label>
                      <textarea
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Enter Diagnose"
                        rows="4"
                        onChange={(e) => setDiagDesc(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <hr className="mt-6 mb-3 border-b-1 border-blueGray-300" />
                <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Upload Document, antara lain:
                    </label>
                    {arUploadFile ? (
                      <ol
                        className="mx-4 text-justify break-words"
                        style={{ listStyle: "number" }}
                        dangerouslySetInnerHTML={{ __html: arUploadFile }}
                      ></ol>
                    ) : (
                      <p>*select benefit first</p>
                    )}
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      htmlFor="dropzone-file"
                      {...getRootProps({ className: "dropzone" })}
                      className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="mb-3 mt-3 w-10 h-10 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          Tekan CTRL untuk memilih beberapa file
                        </p>
                      </div>
                      <input {...getInputProps()} name="fileUpd" id="fileUpd" />
                    </label>
                    <aside>
                      <ul className="w-48 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                        {files}
                      </ul>
                    </aside>
                  </div>
                </div>
                </div>
                <hr className="mt-6 mb-3 border-b-1 border-blueGray-300" />
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3 text-right">
                    <button
                      className={
                        (loading ? "bg-blueGray-600" : "bg-lightBlue-500") +
                        " text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      }
                      type="submit"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <i class="fas fa-spinner fa-spin mr-2"></i>
                      ) : (
                        ""
                      )}
                      {loading ? "Loading" : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
