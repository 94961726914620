import React from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

export default function HeaderStats({ dataUser }) {
  const onDevelopment = () => {
    Swal.fire({
      text: "Halaman masih dalam tahap Pengembangan",
      icon: "info",
      timer: 1500,
      showConfirmButton: false,
      allowOutsideClick: false
    });
  };
  return (
    <>
      {/* Header */}
      <div
        className="relative md:pt-32 pb-32 pt-12 bg-no-repeat bg-full"
        style={{
          backgroundImage:
            "url(" + require("assets/img/register_bg_2.png").default + ")",
          backgroundColor: "#0C2C7A",
        }}
      >
        <div className="px-4 md:px-10 mx-auto w-full">
          <div
            className={
              window.location.href.indexOf("/dashboard") !== -1
                ? "block"
                : "hidden"
            }
          >
            <div className="flex flex-wrap">
              <div className="w-full md:w-12/12 lg:w-6/12 mb-2 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                  <div className=" rounded-t bg-white px-4 py-5">
                    <h5 className="mb-4 text-xl font-bold tracking-tight text-gray-900 uppercase">
                      Hello {dataUser.display_name}!
                    </h5>
                    <Link
                      to="/profile"
                      className=" text-white bg-lightBlue-600 font-bold uppercase text-xs px-4 py-3 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                    >
                      <i className="fas fa-edit text-sm"></i> Complete your
                      Profile
                    </Link>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-12/12 lg:w-6/12 mb-2 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                  <div className="flex-auto bg-white px-4 py-3">
                    <div className="flex flex-wrap">
                      <div className="w-12/12 lg:w-9/12">
                        <div className="relative w-full mb-4">
                          <h3 className="text-sm leading-normal mt-2 mb-2 text-blueGray-400 font-bold uppercase">
                            LET'S KEEP IN CONTACT
                          </h3>
                          <h3 className="font-semibold text-xl text-blueGray-700">
                            Telegram push notification
                          </h3>
                        </div>
                      </div>
                      <div className="w-3/12">
                        <div className="absolute right-0 mx-4 mt-6 text-right">
                          <a
                            href="https://t.me/TuguBro_Health_bot"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-lightBlue-800 rounded-full text-white active:bg-lightBlue-600 font-bold p-5 shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                          >
                            <i className="fab fa-telegram text-xl"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <Link
                  to="/claim/input"
                  className="bg-orange-500 text-white active:bg-red-700 font-bold uppercase text-sm mb-2 text-center px-4 py-5 block rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                >
                  <div className="flex">
                    <div className="flex-none">
                      <i className="fas fa-edit mr-2 text-4xl"></i>
                    </div>
                    <div className="flex-auto">
                      Input Claim <br /> Reimbursement
                    </div>
                  </div>
                </Link>
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <Link
                  to="/"
                  onClick={() => onDevelopment()}
                  className="bg-emerald-500 text-white active:bg-emerald-700 font-bold uppercase text-sm mb-2 text-center px-4 py-5 block rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                >
                  <div className="flex">
                    <div className="flex-none">
                      <i className="fas fa-hospital mr-2 text-4xl"></i>
                    </div>
                    <div className="flex-auto mt-2">RS Provider</div>
                  </div>
                </Link>
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <Link
                  to="/"
                  onClick={() => onDevelopment()}
                  className="bg-lightBlue-500 text-white active:bg-orange-700 font-bold uppercase text-sm mb-2 text-center px-4 py-5 block rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                >
                  <div className="flex">
                    <div className="flex-none">
                      <i className="fas fa-info-circle mr-2 text-4xl"></i>
                    </div>
                    <div className="flex-auto mt-2">Info Kesehatan</div>
                  </div>
                </Link>
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <Link
                  to="/"
                  onClick={() => onDevelopment()}
                  className="bg-red-500 text-white active:bg-red-700 font-bold uppercase text-sm mb-2 text-center px-4 py-5 block rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                >
                  <div className="flex">
                    <div className="flex-none">
                      <i className="fas fa-clock mr-2 text-4xl"></i>
                    </div>
                    <div className="flex-auto mt-2">History Claim</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
