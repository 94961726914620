// import { Link } from "react-router-dom";
import { React, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import axios from 'axios';
import { AES } from 'crypto-js';

export default function Login() {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState([]);
  const history = useHistory();

  useEffect(() => {
      //check token
      if(Cookies.get('__stkn')) {
          history.push('/dashboard');
      }
  }, [history]);

  const loginHandler = async (e) => {
      // stop browser's default behavior of reloading on form submit
      e.preventDefault();
      
      const formData = new FormData();

      formData.append('username', username);
      formData.append('password', password);

      await axios.post('https://health-service.tib.co.id/api/login', formData)
      .then((response) => {
          //set token on localStorage
          // localStorage.setItem('token', response.data.token);

          // Encrypt
          var cypher = AES.encrypt(response.data.token, 'Tugubro@1976').toString();

          // set token on js-cookie
          Cookies.set('__stkn', cypher)

          // redirect to dashboard
          history.push('/dashboard');
      })
      .catch((error) => {
          setValidation(error.response.data);
      })
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h3 className="text-blueGray-500 font-bold">
                    HEALTH TIB
                  </h3>
                </div>
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form onSubmit={loginHandler}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Username"
                      value={username} onChange={(e) => setUsername(e.target.value)}
                    />
                    {
                        validation.message && (
                            <div className="alert alert-danger">
                                {validation.message}
                            </div>
                        )
                    }
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      value={password} onChange={(e) => setPassword(e.target.value)}
                    />
                      {
                          validation.password && (
                              <div className="alert alert-danger">
                                  {validation.password[0]}
                              </div>
                          )
                      }
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
