import { React, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import { AES, enc } from 'crypto-js';


const CustomClientName = ({ row }) => (
  <ul className="ml-2 my-2" style={{listStyle: "disc"}}>
      <li className="my-2">{row.officeName}</li>
      <li className="my-2">{row.crt_name}</li>
      <li className="my-2">{row.period}</li>
      <li className="my-2">
        <span className="text-white rounded" style={{background: "green", padding: "1px 5px"}}>
          {row.stat_act}
        </span>
      </li>
  </ul>
  );

  const CustomMemberName = ({ row }) => (
    <ul className="ml-2 my-2" style={{listStyle: "disc"}}>
        <li className="my-2">Member: {row.fname}</li>
        <li className="my-2">Employee: {row.empName}</li>
    </ul>
  );
  
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="relative">
      <div className="flex absolute inset-y-0 left-0 items-center pl-3 mt-4 pointer-events-none">
          <svg aria-hidden="true" className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
      </div>
      <input type="text" id="search"
      value={filterText}
      onChange={onFilter}
        className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
        placeholder="Search" />
  </div>
);
  
const columns = [
  {
      name: 'CLIENT NAME',
      selector: row => row.officeName,
      sortable: true,
      width: '250px',
      cell: row => <CustomClientName row={row} />,
  },
  {
      name: 'MEMBER NAME',
      selector: row => row.fname,
      sortable: true,
      width: '300px',
      cell: row => <CustomMemberName row={row} />,
  },
  {
      name: 'INCURRED DATE',
      selector: row => row.incured_date,
      sortable: true,
  },
  {
      name: 'RECEIVE DATE',
      selector: row => row.receive_date,
      sortable: true,
  },
  {
      name: 'CLAIM AMOUNT',
      selector: row => row.claim_amt,
      sortable: true,
  },
  {
      name: 'REIMBURSEMENT AMOUNT',
      selector: row => row.reimburse_amt,
      sortable: true,
  },
  {
      name: 'DIAGNOSE',
      selector: row => row.diag_desc,
      sortable: true,
  },
  {
      name: 'ACTION',
      selector: row => row.gender,
      sortable: true,
      cell: row => (
        <a href={'/claim/' + row.claimId} target="_blank" rel="noopener noreferrer"
        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150">
          Detail
        </a>
      ),
  },
];

const ClaimData = () => {
    // get claim id
    const { statusId } = useParams();
    // get data
    const [claimData, setClaimData] = useState([]);
  	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  	const [filterText, setFilterText] = useState('');
    // filtering data table
    const filteredItems = claimData.filter(
      (item) => item.fname && item.fname.toLowerCase().includes(filterText.toLowerCase())
    );
    
  useEffect(() => {
    let isMounted = true;  
    // Get token
    const token = Cookies.get('__stkn') ? AES.decrypt(Cookies.get('__stkn'), 'Tugubro@1976').toString(enc.Utf8) : null;

    const fetchData = async () => {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get("https://health-service.tib.co.id/api/claim/datatable", {
          params: { p_Mode: statusId, statId: localStorage.getItem('role_id') },
        })
        .then(({ data }) => {
          // console.log(data.dataList);
            if (isMounted) {
              setClaimData(data.dataList)
            }
        })
        .catch((error) => console.log(error));
        
      };

    fetchData();
    
    return () => {
      isMounted = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
    <div className="flex flex-wrap">
      <div className="w-full xl:w-full/12 mb-12 xl:mb-0 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-red-600 border-0">
          <div className="rounded-t mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-white text-xl font-bold">
                Data Claim
              </h6>
            </div>
          </div>
          <div className="flex-auto bg-white">
          <DataTable
                columns={columns}
                data={filteredItems}
                direction="auto"
                fixedHeader
                fixedHeaderScrollHeight="500px"
                highlightOnHover
                noContextMenu
                pagination
          			paginationResetDefaultPage={resetPaginationToggle} 			
                subHeader
  			        subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                responsive
                subHeaderAlign="right"
                subHeaderWrap
            />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ClaimData