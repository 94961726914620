import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
// import { swal } from 'sweetalert';
import {useDropzone} from 'react-dropzone';
import Cookies from 'js-cookie';
import { AES, enc } from 'crypto-js';

const ClaimDetail = () => {
  // get claim id
  const { claimId } = useParams();
  // Get token
  const token = Cookies.get('__stkn') ? AES.decrypt(Cookies.get('__stkn'), 'Tugubro@1976').toString(enc.Utf8) : null;

  // get data
  const [dataEmp, setDataEmp] = useState([]);
  const [dataClaim, setDataClaim] = useState([]);
  const [dataFile, setDataFile] = useState([]);
  const [dataLog, setDataLog] = useState([]);

  // Dropzone
  const {getRootProps, getInputProps, open, acceptedFiles} = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true
  });

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .get("https://health-service.tib.co.id/api/claim/detail", {
        params: { claimId: claimId },
      })
      .then(({ data }) => {
        // console.log(data);
        setDataEmp(data.dataEmp);
        setDataClaim(data.dataClaim);
        setDataFile(data.dataFile);
        setDataLog(data.dataLog);
      })
      .catch((error) => console.log(error));
  };
  
  // list file
  const files = acceptedFiles.map(file => (
    <li className="py-2 px-4 w-full border-b border-gray-200 dark:border-gray-600" key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  
  const submitHandler = async (e) => {
        // stop browser's default behavior of reloading on form submit
        e.preventDefault();
        
        const formData = new FormData();
        
        // set claimId
        formData.append('claimId', claimId)

        // upload multiple file
        acceptedFiles.map(file => (
          // console.log(file)
          formData.append('fileUpd[]', file,  file.path)
        ));

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        axios.defaults.headers.post['Accept'] = 'application/json';
        await axios.post('https://health-service.tib.co.id/api/claim/addFile', formData)
        .then((response) => {
          window.location.reload();
            // Swal.fire({
            //   title: "Success!",
            //   text: response.data.message,
            //   icon: "success",
            // });
        }).catch(error => console.log(error));
    };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Claim Detail
            </h6>
          </div>
        </div>
        <div className="flex-auto  bg-white px-4 lg:px-10 py-10 pt-0">
          <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Member Info
          </h6>
          {dataEmp.map((Emp) => (
            <div className="flex flex-wrap" key={Emp}>
              <div className="w-full lg:w-4/12 px-3">
                <div className="relative w-full mb-3">
                  <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        MEMBER NAME
                      </dt>
                      <dd className="text-lg font-semibold">{Emp.fname}</dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        DOB
                      </dt>
                      <dd className="text-lg font-semibold">{Emp.dob}</dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        SEX
                      </dt>
                      <dd className="text-lg font-semibold">
                        {Emp.sex === "M" ? "Male" : "Female"}
                      </dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        STATUS
                      </dt>
                      <dd className="text-lg font-semibold">{Emp.stat_name}</dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        PLAN
                      </dt>
                      <dd className="text-lg font-semibold">{Emp.plan}</dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="w-full lg:w-8/12 px-3">
                <div className="relative w-full mb-3">
                  <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        EMPLOYEE NAME
                      </dt>
                      <dd className="text-lg font-semibold">{Emp.emp_name}</dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        OFFICE
                      </dt>
                      <dd className="text-lg font-semibold">{Emp.name}</dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        POLICY NUMBER
                      </dt>
                      <dd className="text-lg font-semibold">{Emp.policy_no}</dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        PERIOD
                      </dt>
                      <dd className="text-lg font-semibold">
                        {Emp.period_start} up to {Emp.period_end}
                      </dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        STATUS ACTIVE
                      </dt>
                      <dd className="text-lg font-semibold">{Emp.stat_act}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          ))}

          <hr className="mt-6 mb-3 border-b-1 border-blueGray-300" />
          <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Data Claim
          </h6>
          {dataClaim.map((Claim) => (
            <div className="flex flex-wrap" key={Claim}>
              <div className="w-full lg:w-4/12 px-3">
                <div className="relative w-full mb-3">
                  <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                    <div className="flex flex-col pb-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        INCURED DATE
                      </dt>
                      <dd className="text-lg font-semibold">
                        {Claim.incured_date ? Claim.incured_date : "-"}
                      </dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        RECEIVE DATE
                      </dt>
                      <dd className="text-lg font-semibold">
                        {Claim.receive_date ? Claim.receive_date : "-"}
                      </dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        CLAIM AMOUNT
                      </dt>
                      <dd className="text-lg font-semibold">
                        {Claim.claim_amt_curr_id}{" "}
                        {Claim.claim_amt_curr_id ? Claim.claim_amt : "-"}
                      </dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        REIMBURSE AMOUNT
                      </dt>
                      <dd className="text-lg font-semibold">
                        {Claim.reimburse_amt_curr_id}{" "}
                        {Claim.reimburse_amt_curr_id
                          ? Claim.reimburse_amt
                          : "-"}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="w-full lg:w-8/12 px-3">
                <div className="relative w-full mb-3">
                  <dl className="max-w-md text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        BENEFIT
                      </dt>
                      <dd className="text-lg font-semibold">
                        {Claim.benefit ? Claim.benefit : "-"}
                      </dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        DIAGNOSE
                      </dt>
                      <dd className="text-lg font-semibold">
                        {Claim.diag_desc ? Claim.diag_desc : "-"}
                      </dd>
                    </div>
                    <div className="flex flex-col py-3">
                      <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                        DOCUMENT
                      </dt>
                      <ul className="mx-4 text-justify break-words" style={{listStyle: "disc"}}>
                        {dataFile.map((File) => (
                          <li
                            key={File.id}
                          >
                              <a
                                href={`https://int.tib.co.id${File.file_path}`}
                                target="blank"
                              >
                                {File.file_name ? File.file_name : "-"}
                              </a>
                          </li>
                        ))}
                      </ul>
                      <div className={Claim.statId === 2 ? "block" : "hidden"}>
                        <hr className="mt-3" />
                        <form  onSubmit={submitHandler}>
                          <div className="flex flex-wrap">
                            <div className="w-full lg:w-12/12 px-4">
                              <div className="relative w-full mb-3">
                                <label
                                 {...getRootProps({className: 'dropzone'})}
                                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  htmlFor="file_input"
                                >
                                  Upload additional file
                                </label>
                                <input
                                  {...getInputProps()} name='fileUpd' id='fileUpd'
                                  type="file"
                                />
                                <button 
                                  type="button" onClick={open}
                                  className=" bg-blueGray-500 active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                  >
                                  <i className="fas fa-upload mr-2" />
                                  Select File
                                </button>
                                <small>Tekan CTRL untuk memilih beberapa file</small>
                                <aside>
                                  <ul className="w-48 my-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                                    {files}
                                  </ul>
                                </aside>
                              </div>
                            </div>
                          </div>
                          <div className="w-full lg:w-12/12 px-4">
                            <div className="relative w-full mb-3 text-right">
                              <button
                                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                type="submit"
                              >
                                Upload
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Log Claim
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Log Status
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Log Date
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Log Description
                </th>
              </tr>
            </thead>
            <tbody>
              {dataLog.map((log, index) => (
                <tr key={index}>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                    {log.log_date}
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {log.stat_name}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {log.descriptions}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ClaimDetail;