import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { AES, enc } from 'crypto-js';

import ModalEditProfile from "components/Modals/ModalEditProfile";

const ProfileData = () => {
  // Get token
  const token = Cookies.get('__stkn') ? AES.decrypt(Cookies.get('__stkn'), 'Tugubro@1976').toString(enc.Utf8) : null;

  // get data
  const [InfoMember, setInfoMember] = useState([]);
  const [InfoLogin, setInfoLogin] = useState([]);
  const [InfoTelegram, setInfoTelegram] = useState([]);

  // update data
  const [usernameProfile, setUsernameProfileProfile] = useState("");
  const [emailProfile, setEmailProfile] = useState("");

  // modal
  const [show, setShow] = useState(false);

  // loading
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .get("https://health-service.tib.co.id/api/profile/get-data")
      .then(({ data }) => {
        // console.log(data);
        setInfoMember(data.infoMember);
        setInfoLogin(data.infoLogin);
        setInfoTelegram(data.infoTele);
      })
      .catch((error) => console.log(error));
  };

  const editHandler = async (e) => {
    // stop browser's default behavior of reloading on form submit
    e.preventDefault();

    const params = new URLSearchParams();

    params.append("mode", 1);
    params.append("username", usernameProfile);
    params.append("email", emailProfile);

    // loading before call API
    setLoading(true);

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .put("https://health-service.tib.co.id/api/profile/update-data", params)
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: "Berhasil mengubah data",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
          allowOutsideClick: false
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Failed!",
          text: "Periksa kembali username dan email",
          icon: "warning",
          timer: 1500,
          showConfirmButton: false,
          allowOutsideClick: false
        });
      });
  };

  const deleteHandler = async (chat_id) => {
    // await console.log(chat_id)
    const params = new URLSearchParams();

    params.append("mode", 2);
    params.append("chat_id", chat_id);

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .put("https://health-service.tib.co.id/api/profile/update-data", params)
      .then(() => {
        Swal.fire({
          title: "Success!",
          text: "Berhasil menghapus data",
          icon: "success",
        });
      })
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
        <div className="px-2">
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-4 flex justify-center">
              <div className="relative bg-blueGray-500">
                <img
                  alt="userImage"
                  src={require("assets/img/user.png").default}
                  className="shadow-xl rounded-lg h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                  style={{
                    backgroundColor: '#fff',
                  }}/>
              </div>
            </div>
            <div className="relative w-full max-w-full flex-grow flex-1 text-right">
              <button
                className="modalButton bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-3 rounded outline-none focus:outline-none mt-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShow(true)}
              >
                <i className="fas fa-edit text-sm block md:hidden"></i>
                <span className="hidden md:block">
                  <i className="fas fa-edit text-sm"></i> Edit profile
                </span>
              </button>
            </div>
          </div>
          {InfoMember.map((member, index) => (
            <div className="text-center mt-12" key={index + 1}>
              <h3 className="text-xl font-semibold leading-normal mb-2 text-blueGray-700">
                {member.display_name}
              </h3>
              <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold">
                <h4>( {member.username} )</h4>
              </div>
              <div className="flex-auto bg-white text-left px-4 py-3">
                <div className="flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-2">
                      <div className="text-sm mb-2 text-blueGray-600">
                        <i className="fas fa-building mr-2 text-lg text-blueGray-400"></i>
                        {member.clientName}
                      </div>
                      <div className="text-sm mb-2 text-blueGray-600">
                        <i className="fas fa-envelope mr-2 text-lg text-blueGray-400"></i>
                        {member.email}
                      </div>
                      <div className="text-sm mb-2 text-blueGray-600">
                        <i className="fas fa-calendar mr-2 text-lg text-blueGray-400"></i>
                        {member.dob}
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-6/12 px-4">
                    <div className="relative w-full mb-2">
                      <div className="text-sm mb-2 text-blueGray-600">
                        <i className="fas fa-briefcase  mr-2 text-lg text-blueGray-400"></i>
                        {member.insuranceName}
                      </div>
                      <div className="text-sm mb-2 text-blueGray-600">
                        <i className="fas fa-file-alt  mr-2 text-lg text-blueGray-400"></i>
                        {member.policy_no}
                      </div>
                      <div className="text-sm mb-2 text-blueGray-600">
                        <i className="fas fa-business-time mr-2 text-lg text-blueGray-400"></i>
                        {member.period}
                      </div>
                      <div className="text-sm mb-5 text-blueGray-600">
                        <span
                          className={
                            (member.stat_act.toString() === "ACTIVE"
                              ? "bg-emerald-500 text-white active:bg-emerald-600"
                              : "bg-red-500 text-white active:bg-red-600") +
                            "font-bold uppercase text-xs text-center px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                          }
                        >
                          {member.stat_act}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="flex-auto bg-white px-4 py-3">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-8/12 px-4">
              <div className="relative w-full mb-2">
                <h3 className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                  LET'S KEEP IN CONTACT
                </h3>
                <h3 className="font-semibold text-xl text-blueGray-700">
                  Telegram push notification
                </h3>
              </div>
            </div>
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative w-full mb-2 mt-4">
                <a
                  href="https://t.me/TuguBro_Health_bot"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs text-center px-4 py-2 block rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                >
                  <i className="fas fa-bell mr-2 text-lg text-white"></i>{" "}
                  Subscribe
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative flex flex-col min-w-0 break-words bg-red-600 w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-white">
                Login Info
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto bg-white">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  IP Address
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Device
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Browser
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {InfoLogin.map((login, index) => (
                <>
                  <tr key={index + 1}>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {login.ip}
                    </th>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {login.type === 1 ? (
                        <i className="fas fa-mobile-alt mr-2 text-lg text-blueGray-400"></i>
                      ) : (
                        <i className="fas fa-desktop mr-2 text-lg text-blueGray-400"></i>
                      )}
                      {login.type === 1 ? "Mobile" : "Desktop"}
                    </th>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {login.browser}
                    </th>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {login.login_data}
                    </th>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="relative flex flex-col min-w-0 break-words bg-red-600 w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-white">
                Telegram Info
              </h3>
            </div>
            {/* <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
              >
                See all
              </button>
            </div> */}
          </div>
        </div>
        <div className="block w-full overflow-x-auto bg-white">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Name
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Username
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Date
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {InfoTelegram.map((telegram, index) => (
                <>
                  <tr key={index + 1}>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {telegram.display_name}
                    </th>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {telegram.username}
                    </th>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {telegram.login_date}
                    </th>
                    <th>
                      <button
                        onClick={() => deleteHandler(telegram.chat_id)}
                        className="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs text-center px-4 py-2 block rounded shadow hover:shadow-md outline-none focus:outline-none ml-2 ease-linear transition-all duration-150"
                      >
                        <i className="fas fa-trash mr-2 text-lg text-white"></i>
                        Delete
                      </button>
                    </th>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ModalEditProfile show={show} onClose={() => setShow(false)}>
        <>
          {InfoMember.map((member, index) => (
            <div
              className="relative p-4 w-full max-w-md h-full md:h-auto"
              key={index + 1}
            >
              <div className="relative">
                <button
                  type="button"
                  className="absolute top-3 text-right right-0 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                  onClick={() => setShow(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
                <div className="py-6 px-6 lg:px-8">
                  <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                    Edit profile
                  </h3>
                  <form onSubmit={editHandler}>
                    <div>
                      <label
                        htmlFor="username"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Username
                      </label>
                      <input
                        type="text"
                        name="username"
                        id="username"
                        defaultValue={member.username}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        onChange={(e) => {
                          setUsernameProfileProfile(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        defaultValue={member.email}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        onChange={(e) => {
                          setEmailProfile(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className={
                        (loading ? "bg-blueGray-600" : "bg-lightBlue-500") +
                        " w-full text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-3 mt-3 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      }
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <i class="fas fa-spinner fa-spin mr-2"></i>
                      ) : (
                        ""
                      )}
                      {loading ? "Loading" : "Save"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          ))}
        </>
      </ModalEditProfile>
    </>
  );
};

export default ProfileData;
