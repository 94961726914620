import React from "react";
import styles from "./modal.module.css";

const ModalEditProfile = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        {children}
      </div>
    </div>
  );
};

export default ModalEditProfile;
