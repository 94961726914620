import React from "react";
import { useHistory } from 'react-router';
import axios from 'axios';
import { createPopper } from "@popperjs/core";
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import { AES, enc } from 'crypto-js';


const UserDropdown = () => {
  const history = useHistory();
  
  const logoutHandler = async (e) => {
      e.preventDefault();
      
      const token = Cookies.get('__stkn') ? AES.decrypt(Cookies.get('__stkn'), 'Tugubro@1976').toString(enc.Utf8) : null;

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      await axios.post('https://health-service.tib.co.id/api/logout')
      .then(() => {
          Cookies.remove('__stkn');
          history.push('/');
      });
  };
  
  const logoutSessionHandler = async () => {
    Cookies.remove('__stkn');
};
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  
  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#user"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={require("assets/img/user.png").default}
            />
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <Link
          to="/profile"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
        >
          Profile
        </Link>
        <div className="h-0 my-2 border border-solid border-blueGray-100" />
        <a
          href="/"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={logoutSessionHandler}
        >
          Logout
        </a>
        <a
          href="/"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={logoutHandler}
        >
          Logout from all device
        </a>
      </div>
    </>
  );
};

export default UserDropdown;
